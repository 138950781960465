import { RemoteContext } from 'fitify-ui/src/hooks/useRemoteConfig'
import { CrossIcon } from 'fitify-ui/src/Icon/components/CrossIcon'
import {
  LayoutContent,
  LayoutStack,
  SubscriptionPromoInfoBoxStyled,
  toast,
} from 'fitify-ui-onboarding/src/components'
import Header from 'fitify-ui-onboarding/src/components/Header/Header'
import { StyledPromoRedirect } from 'fitify-ui-onboarding/src/components/Header/Header.Styled'
import { useUserContext } from 'fitify-ui-onboarding/src/contexts/UserContext'
import { useBeforeLeaveDialog } from 'fitify-ui-onboarding/src/hooks/useBeforeLeaveDialog'
import { getPaging } from 'fitify-ui-onboarding/src/hooks/useNavigation'
import { usePromo } from 'fitify-ui-onboarding/src/hooks/usePromo'
import { useRouter } from 'fitify-ui-onboarding/src/hooks/useRouter'
import { encodeQuery } from 'fitify-ui-onboarding/src/utils'
import { useTranslation } from 'next-i18next'
import { NextSeo } from 'next-seo'
import { OpenGraph } from 'next-seo/lib/types'
import {
  CSSProperties,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'

import { getImageProps } from 'utils/images'
import { APP_PAGES, ONBOARDING_PAGES } from 'utils/routes'
import { DataContext } from 'utils/state'

interface LayoutProps {
  seo?: {
    title?: string
    description?: string
    openGraph?: OpenGraph
  }
  options?: {
    isFullscreen?: boolean
    isPrevHidden?: boolean
    isHeaderHidden?: boolean
    hasShadow?: boolean
    hasBackground?: boolean
  }
  layoutStyle?: CSSProperties
  children: ReactNode
  className?: string
}

const SubscriptionPromoInfoBox = () => {
  const { t } = useTranslation()

  return (
    <SubscriptionPromoInfoBoxStyled>
      {t('promo_info_active_title')}
    </SubscriptionPromoInfoBoxStyled>
  )
}

export function Layout({
  options,
  layoutStyle,
  children,
  className,
  seo,
}: LayoutProps) {
  const { t } = useTranslation()
  const router = useRouter()
  const config = useContext(RemoteContext)
  const { data, clear } = useContext(DataContext)
  const [isPromoActive, setIsPromoActive] = useState<boolean>(false)

  const promoCode = usePromo()
  const { user, logout } = useUserContext()

  const { onboarding_payment_close_promo } = config

  const noLeaveDialogPages = [
    ONBOARDING_PAGES.home.path,
    ONBOARDING_PAGES.subscription.path,
    ONBOARDING_PAGES.success.path,
    APP_PAGES.login.path,
    APP_PAGES.loginEmail.path,
    APP_PAGES.signup.path,
    APP_PAGES.passwordReset.path,
    APP_PAGES.giftSuccess.path,
    '/404',
    '/500',
  ]

  useBeforeLeaveDialog(!noLeaveDialogPages.includes(router.route))

  // isPromoActive needs to be resolved on client preventing server-client text mismatch
  useEffect(() => {
    const noPromoPages = [
      ONBOARDING_PAGES.subscription.path,
      ONBOARDING_PAGES.success.path,
      APP_PAGES.gift.path,
      APP_PAGES.giftSuccess.path,
      APP_PAGES.redeem.path,
      APP_PAGES.redeemSuccess.path,
      '/404',
      '/500',
    ]

    setIsPromoActive(promoCode !== null && !noPromoPages.includes(router.route))
  }, [promoCode, router.route])

  const isSubscriptionScreen =
    router.route === ONBOARDING_PAGES.subscription.path

  const handleOnPrevClick = () => {
    const paging = getPaging({
      path: router.pathname,
      data,
    })

    if (paging?.prev) {
      router.replace(paging.prev.path)
    } else {
      router.back()
    }
  }

  const handleLogout = async () => {
    try {
      await logout()
      clear() // clear state and promo

      router.push(ONBOARDING_PAGES.home.path)
    } catch (err) {
      console.error(err)
      toast({ type: 'error', content: 'Failed to log out' })
    }
  }

  const handleRedirectToPromo = () => {
    router.push(
      encodeQuery(ONBOARDING_PAGES.subscription.path, { promo: 'sweat30' })
    )
  }

  const logo = {
    ...getImageProps('common', 'logo'),
    $responsive: {
      width: 90,
      height: 28,
    },
  }

  const closeButton =
    promoCode === null &&
    isSubscriptionScreen &&
    onboarding_payment_close_promo ? (
      <StyledPromoRedirect isHidden={false} onClick={handleRedirectToPromo}>
        <CrossIcon />
      </StyledPromoRedirect>
    ) : null

  return (
    <>
      <NextSeo
        title={seo?.title}
        description={seo?.description}
        openGraph={seo?.openGraph}
      />
      {!options?.isHeaderHidden && (
        <Header
          components={{
            logo: logo,
            closeButton: closeButton,
          }}
          data={{
            user: user,
            promo: promoCode,
            logoutText: t('settings_logout_title'),
          }}
          actions={{
            handleLogout,
            handleOnPrevClick,
          }}
          options={{
            isPrevHidden: options?.isPrevHidden,
          }}
        />
      )}
      <LayoutStack orientation="horizontal" xs="5.125rem">
        <LayoutContent
          $isFullscreen={options?.isFullscreen}
          $isPromoActive={isPromoActive}
          $hasShadow={options?.hasShadow}
          $hasBackground={options?.hasBackground}
          style={layoutStyle}
          className={className}
        >
          {options?.isPrevHidden}

          {isPromoActive && <SubscriptionPromoInfoBox />}
          {children}
        </LayoutContent>
      </LayoutStack>
    </>
  )
}
